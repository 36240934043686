import React from 'react'

export const residentialSessionTimeIntervals = [
  {
    label: '1 min',
    value: '60',
  },
  {
    label: '5 mins',
    value: '300',
  },
  {
    label: '10 mins',
    value: '600',
  },
  {
    label: '60 mins',
    value: '3600',
  },
  {
    label: 'Custom, secs',
    value: 'custom',
  },
]

export const mobileSessionTimeIntervals = [
  {
    label: '1 min',
    value: '60',
  },
  {
    label: '5 mins',
    value: '300',
  },
  {
    label: '10 mins',
    value: '600',
  },
  {
    label: '60 mins',
    value: '3600',
  },
  {
    label: 'Custom, secs',
    value: 'custom',
  },
]

export const dcSessionTimeIntervals = [
  {
    label: '10 mins',
    value: '600',
  },
  {
    label: '60 mins',
    value: '3600',
  },
  {
    label: '12 hours',
    value: '43200',
  },
  {
    label: '24 hours',
    value: '86400',
  },
  {
    label: 'Custom, secs',
    value: 'custom',
  },
]

export const ispSessionTimeIntervals = [
  {
    label: '10 mins',
    value: '600',
  },
  {
    label: '60 mins',
    value: '3600',
  },
  {
    label: '12 hours',
    value: '43200',
  },
  {
    label: '24 hours',
    value: '86400',
  },
  {
    label: 'Custom, secs',
    value: 'custom',
  },
]

export const residentialSessionDefaultInterval = {
  label: '5 mins',
  value: '300',
}

export const mobileSessionDefaultInterval = {
  label: '5 mins',
  value: '300',
}

export const dcSessionDefaultInterval = {
  label: '10 mins',
  value: '600',
}

export const ispSessionDefaultInterval = {
  label: '10 mins',
  value: '600',
}

export const dcAvailableCountries = {
  de: {
    name: 'Germany',
    id: 57,
    code: 'de',
  },
  es: {
    name: 'Spain',
    id: 68,
    code: 'es',
  },
  fr: {
    name: 'France',
    id: 75,
    code: 'fr',
  },
  pl: {
    name: 'Poland',
    id: 179,
    code: 'pl',
  },
  gb: {
    name: 'United Kingdom',
    id: 77,
    code: 'gb',
  },
  us: {
    name: 'United States',
    id: 234,
    code: 'us',
  },
  jp: {
    name: 'Japan',
    id: 114,
    code: 'jp',
  },
}

export const ispAvailableCountries = {
  us: {
    name: 'United States',
    id: 234,
    code: 'us',
  },
}

export const curlResultSample =
  'curl -k -x package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000 -L https://checker.soax.com/api/ipinfo'

export const credResultSample1 =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}'

export const credResultSample =
  'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}'

export const urlResultSample1 =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2@proxy.soax.com:5000'

export const urlResults = {
  option1:
    'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000',
  option2:
    '{{host}}:5000:package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}',
  option3:
    'http://package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000',
  option4:
    'socks5://package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000',
  option5:
    'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}:{{host}}:5000',
  option6:
    'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}},{{package_key}},{{host}},5000',
}

export const urlResultSample =
  'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000'

export const proxyListResultSample1 =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2@proxy.soax.com:5000'

export const proxyListResultSample =
  'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000'

export const proxyListResults = {
  option1:
    'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000',
  option2:
    '{{host}}:5000:package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}',
  option3:
    'http://package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000',
  option4:
    'socks5://package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@{{host}}:5000',
  option5:
    'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}:{{host}}:5000',
  option6:
    'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}},{{package_key}},{{host}},5000',
}

export const getProxyPoolDescription = () => (
  <div>
    <p>
      <b>Residential Proxies:</b> Uses IP addresses from real homes, making it
      appear as legitimate user traffic, ideal for web scraping and accessing
      geo-restricted content.
    </p>
    <p>
      <b>Mobile Proxies:</b> Uses IPs from mobile carriers, frequently rotating,
      providing high anonymity for mobile app testing and managing multiple
      accounts.
    </p>
    <p>
      <b>Datacenter Proxies:</b> Uses IPs from data centers, offering high speed
      and cost-effectiveness, but easier to detect and block.
    </p>
    <p>
      <b>ISP Proxies:</b> Combines ISP-provided IPs with datacenter hosting,
      offering a balance of reliability, speed, and anonymity for tasks like
      streaming and market research.
    </p>
  </div>
)

export const ProxyPoolDescription = getProxyPoolDescription()

export const defaultHostValue = 'proxy.soax.com'

export const defaultSpaceSymbol = '%20'
export const defaultAmpersandSymbol = '%26'

export const defaultOutputMaskOption = {
  label: 'username : password @ host : port',
  value: 'option1',
  notice: 'Software ready',
}

export const outputMaskOptions = [
  {
    label: 'username : password @ host : port',
    value: 'option1',
    notice: 'Software ready',
  },
  {
    label: 'host : port : username : password',
    value: 'option2',
    notice: 'Proxy checker ready',
  },
  {
    label: 'http:// username : password @ host : port',
    value: 'option3',
  },
  {
    label: 'socks5:// username : password @ host : port',
    value: 'option4',
  },
  {
    label: 'username : password : host : port',
    value: 'option5',
  },
  {
    label: 'username , password , host , port',
    value: 'option6',
  },
]
