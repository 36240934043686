import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styles from './FullActivationModal.module.scss'

import CustomModal from 'components/elements/modal/CustomModal'
import CustomButton from 'components/elements/buttons/CustomButton'
import {
  CaretRight as CaretRightIcon,
  BatteryFull as BatteryFullIcon,
  BatteryLow as BatteryLowIcon,
} from '../../../icons'

import {
  selectInvoiceModalStatus,
  selectSubscriptionProcessing,
} from 'store/pricing/selectors'
import { PricingActionsTypes } from 'store/pricing/actions'
import { logAmplitudeEvent } from 'utils/analytics'

interface IFullActivationModal {
  isModalShown: boolean
  fullPlanPrice: string | null
  fullPlanId: string
  onModalClose: () => void
}

const FullActivationModal: React.FC<IFullActivationModal> = (props) => {
  const { isModalShown, fullPlanPrice, fullPlanId, onModalClose } = props

  const dispatch = useDispatch()

  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)
  const isInvoiceModalShown = useSelector(selectInvoiceModalStatus)

  const handleClose = () => {
    onModalClose()
  }

  const handleConfirm = () => {
    logAmplitudeEvent('click Activate-full confirmed')

    const subscriptionData = {
      plan_id: fullPlanId,
    }

    dispatch({
      type: PricingActionsTypes.PRICING_GET_INVOICE,
      subscriptionData,
    })
  }

  useEffect(() => {
    if (isInvoiceModalShown) {
      onModalClose()
    }
  }, [isInvoiceModalShown, onModalClose])

  return (
    <CustomModal
      title={'Upgrade your subscription'}
      isModalOpen={isModalShown}
      shouldCloseOnOverlayClick
      showCross
      modalWidth={'560px'}
      className={styles.container}
      onModalClose={handleClose}
    >
      <>
        <div className={styles.info}>
          <div className={styles.infoItem}>
            <div className={styles.infoItemIconContainer}>
              <BatteryLowIcon />
            </div>
            <div className={styles.infoItemTitle}>Trial plan</div>
            <div className={styles.infoItemDesc}> 3 days</div>
          </div>

          <div className={styles.arrowIconContainer}>
            <CaretRightIcon />
          </div>

          <div className={styles.infoItem}>
            <div className={styles.infoItemIconContainer}>
              <BatteryFullIcon />
            </div>
            <div className={styles.infoItemTitle}>Full plan</div>
            <div className={styles.infoItemDesc}> 30 days</div>
          </div>
        </div>

        <div className={styles.description}>
          By clicking <b>Confirm upgrade</b>, you will upgrade your current
          trial to the full plan and pay $<b>{fullPlanPrice || ''}</b> (plus
          applicable taxes).
        </div>
        <CustomButton
          className={styles.submitBtn}
          color={'blue'}
          loading={subscriptionProcessing}
          onClick={handleConfirm}
        >
          Confirm upgrade
        </CustomButton>
      </>
    </CustomModal>
  )
}

export default FullActivationModal
