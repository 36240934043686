import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styles from './SubscriptionSettings.module.scss'

import SubscriptionInfo from '../SubscriptionsInfo'
import CustomButton from '../../../elements/buttons/CustomButton'

import { selectCancelSubscriptionProcessing } from 'store/pricing/selectors'
import { selectSubscriptionInfo } from 'store/user/selectors'
import { PricingActionsTypes } from 'store/pricing/actions'

const SubscriptionSettings: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const subscriptionInfo = useSelector(selectSubscriptionInfo)

  const handleInvoicesClick = () => {
    navigate('/statistics')
  }

  const cancelSubscriptionProcessing = useSelector(
    selectCancelSubscriptionProcessing
  )

  const handleCancelSubscr = () => {
    dispatch({ type: PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION })
  }

  // const handleGoToPricingClick = () => navigate('/pricing')

  const isAvailableToCancel =
    subscriptionInfo?.status === 'active' ||
    subscriptionInfo?.status === 'paused' ||
    subscriptionInfo?.status === 'trial'

  // const isAvailableToChange =
  //   subscriptionInfo?.status === 'paused' ||
  //   subscriptionInfo?.status === 'active' ||
  //   subscriptionInfo?.status === 'unpaid'

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <h3 className={styles.title}>Subscription</h3>

        <div className={styles.subscrWrapper}>
          <SubscriptionInfo />
        </div>

        <div className={styles.controls}>
          <CustomButton color={'outline'} onClick={handleInvoicesClick}>
            Invoices
          </CustomButton>

          {/*{isAvailableToChange && (
            <CustomButton color={'outline'} onClick={handleGoToPricingClick}>
              Change plan
            </CustomButton>
          )}*/}

          {isAvailableToCancel && (
            <CustomButton
              color={'outline'}
              onClick={handleCancelSubscr}
              loading={cancelSubscriptionProcessing}
            >
              Cancel Subscription
            </CustomButton>
          )}
        </div>
      </div>

      {/*<div className={styles.block}>
        <h3 className={styles.title}>Invoices</h3>
      </div>

      <div className={styles.block}>
        <h3 className={styles.title}>Billing info</h3>
      </div>*/}
    </div>
  )
}

export default SubscriptionSettings
