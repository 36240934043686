import React from 'react'

import styles from './StatusContent.module.scss'

const StatusContent: React.FC = () => {
  return (
    <section className={styles.container}>
      <iframe
        src={'https://status.soax.com/'}
        frameBorder="0"
        title={'Orb statistics'}
        allow="camera;microphone"
      />
    </section>
  )
}

export default StatusContent
