import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.958 9.167c0 .345-.28.625-.625.625H6.667a.625.625 0 0 1 0-1.25h6.666c.345 0 .625.28.625.625"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m3.655 6.789.99-2.706c.25-.682.855-1.171 1.575-1.271a27.4 27.4 0 0 1 7.56 0c.72.1 1.325.589 1.575 1.27l.99 2.707c.186.29.313.62.367.976l.085.565c.333 2.215.302 4.47-.092 6.676a2.22 2.22 0 0 1-1.966 1.818l-1.026.102c-2.47.245-4.957.245-7.426 0l-1.026-.102a2.22 2.22 0 0 1-1.966-1.818 20.6 20.6 0 0 1-.092-6.676l.085-.565c.054-.356.181-.687.367-.976m2.737-2.74a26.2 26.2 0 0 1 7.216 0 .72.72 0 0 1 .573.463l.422 1.153-.114-.014-.816-.081a37 37 0 0 0-7.347 0l-.815.08-.114.015.422-1.153a.72.72 0 0 1 .573-.462M4.738 7.418l.016.006.019-.053c.205-.263.512-.44.862-.475l.815-.081c2.36-.235 4.74-.235 7.1 0l.815.08c.35.035.657.213.862.477l.02.052.015-.006c.11.155.184.337.214.534l.085.565c.313 2.08.283 4.198-.087 6.27a.97.97 0 0 1-.859.795l-1.025.101a36.4 36.4 0 0 1-7.18 0l-1.025-.101a.97.97 0 0 1-.86-.795c-.37-2.072-.399-4.19-.086-6.27l.085-.565c.03-.197.105-.379.214-.534"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBox
