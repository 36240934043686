import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBatteryFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#3C4253"
      d="M25.125 21.312v-6.627c0-.906-.696-1.66-1.6-1.732a93 93 0 0 0-14.682 0l-.283.023a1.51 1.51 0 0 0-1.35 1.172 17.5 17.5 0 0 0 0 7.68c.144.642.689 1.117 1.345 1.17l.262.022c4.903.405 9.831.405 14.735 0a1.714 1.714 0 0 0 1.573-1.708"
    />
    <path
      fill="#3C4253"
      fillRule="evenodd"
      d="M33.375 21v-6a2.625 2.625 0 0 0-2.625-2.625h-.975v-.09a3.65 3.65 0 0 0-3.367-3.641l-1.903-.148a103 103 0 0 0-16.01 0l-2.021.157a3.96 3.96 0 0 0-3.6 3.296 36.6 36.6 0 0 0 0 12.102 3.96 3.96 0 0 0 3.6 3.296l2.021.157c5.329.416 10.681.416 16.01 0l1.902-.148a3.65 3.65 0 0 0 3.368-3.64v-.091h.975A2.625 2.625 0 0 0 33.375 21m-2.25-6v6a.375.375 0 0 1-.375.375h-2.1c-.621 0-1.125.504-1.125 1.125v1.215c0 .732-.563 1.34-1.292 1.398l-1.903.148c-5.212.406-10.448.406-15.66 0l-2.021-.158a1.71 1.71 0 0 1-1.556-1.424c-.63-3.76-.63-7.598 0-11.358a1.71 1.71 0 0 1 1.556-1.424l2.021-.158a101 101 0 0 1 15.66 0l1.903.148c.73.057 1.292.666 1.292 1.397V13.5c0 .621.504 1.125 1.125 1.125h2.1c.207 0 .375.168.375.375"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBatteryFull
