import React from 'react'
// import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import styles from './MainHeader.module.scss'

import { getNormalizedPrice } from 'utils/functions'

import Breadcrumbs, {
  IBreadcrumbsProps,
} from 'components/navigation/Breadcrumbs/Breadcrumbs'
import {
  Wallet as WalletIcon,
  /*Notification as NotificationIcon,*/
} from '../../icons'
import Loader from 'components/elements/progress/Loader'

import {
  selectBalanceInfo,
  selectBalanceInfoFetching,
} from 'store/user/selectors'

interface IMainHeaderProps {
  crumbsItems: IBreadcrumbsProps['items']
  reducedDisplayMode?: boolean
}

const MainHeader: React.FC<IMainHeaderProps> = (props) => {
  const { crumbsItems, reducedDisplayMode } = props

  const balanceInfo = useSelector(selectBalanceInfo)
  const balanceInfoFetching = useSelector(selectBalanceInfoFetching)

  const balance = balanceInfo ? getNormalizedPrice(balanceInfo.balance) : 'N/A'

  /*const handleNotifications = () => console.log('Notific')*/

  return (
    <header className={styles.header}>
      <Breadcrumbs items={crumbsItems} />

      {!reducedDisplayMode && (
        <div className={styles.itemsContainer}>
          <div className={styles.item}>
            <span className={styles.iconWrapper}>
              <WalletIcon />
            </span>

            <span className={styles.itemLabel}>
              <div className={styles.balanceLabel}>Balance&nbsp;</div>
              {balanceInfoFetching ? (
                <div className={styles.loaderContainer}>
                  <Loader />
                </div>
              ) : (
                ` $${balance}`
              )}
            </span>
          </div>

          {/*<Link to={'/balance'} className={styles.item}>
          <span className={styles.iconWrapper}>
            <WalletIcon />
          </span>

          <span className={styles.itemLabel}>{`Balance $${balance}`}</span>
        </Link>*/}

          {/*<Link to={'/profile'} className={styles.item}>
          <span className={styles.iconWrapper}>
            <ProfileIcon />
          </span>

          <span className={styles.itemLabel}>Profile</span>
        </Link>*/}

          {/*<button className={styles.item} onClick={() => handleNotifications()}>
          <span className={styles.iconWrapper}>
            <NotificationIcon />
          </span>

          <span className={styles.itemLabel}>Notifications</span>
        </button>*/}
        </div>
      )}
    </header>
  )
}

export default MainHeader
