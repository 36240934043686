export const popularPlansId = [
  '9pts9w43u36zQmmp',
  'U9VMKTNzMudysft7',
  'HwnAnHnxVv9goUph',
  'ERchD8ye48ih6nUx',
  'fWbiWyNu22Zmntpo',
  '9RHDyJkfP7e7fwZT',
]

// export const popularPlansId = ['kek']

// export const blackFridayPlansId = ['CWgDx5To8jDybWzZ', 'iMBYZvjNpNkUJp55']
export const blackFridayPlansId = ['kek']

export const workingSubscriptionStatuses = [
  'active',
  'trial',
  'cancelled',
  /*'paused',*/
]

export const pricingStructure = {
  proxy: {
    label: 'Proxies',
    values: [
      {
        label: 'Residential Proxies',
        productId: 'wifi',
      },
      {
        label: 'Mobile Proxies',
        productId: 'mobile',
      },
      {
        label: 'ISP Proxies',
        productId: 'isp',
      },
      {
        label: 'DC Proxies',
        productId: 'dc',
      },
    ],
  },
  scraping: {
    title: 'Scraping API',
    ecommerce: [
      {
        label: '1688 Direct API',
        productId: 'ecommerce_1688_api',
      },
      {
        label: 'Alibaba Direct API',
        productId: 'ecommerce_alibaba_api',
      },
      {
        label: 'eCommerce High Load API',
        productId: 'ecommerce_high_load',
      },
      {
        label: 'Lazada Direct API',
        productId: 'ecommerce_lazada_api',
      },
      /*{
        label: 'Shopee Direct API',
        productId: 'ecommerce_shopee_unblocker',
      },*/
      {
        label: 'TaoBao Direct API',
        productId: 'ecommerce_taobao_api',
      },
      {
        label: 'eCommerce Top Tier API',
        productId: 'ecommerce_top_tier',
      },
      {
        label: 'Walmart Direct API',
        productId: 'ecommerce_walmart_unblocker',
      },
    ],
    unblocker: [
      {
        label: 'Unblocker Top Tier API',
        productId: 'unblocker_top_tier',
      },
      /*{
        label: 'Unblocker Advanced Request',
        productId: 'unblocker_extras_jsexec',
      },*/
    ],
    serp: [
      {
        label: 'Baidu Search Advanced API',
        productId: 'serp_advanced_baidu_search',
      },
      {
        label: 'Bing Search Advanced API',
        productId: 'serp_advanced_bing_search',
      },
      {
        label: 'DuckDuckGo Search Advanced API',
        productId: 'serp_advanced_duckduckgo_search',
      },
      {
        label: 'Google Autocomplete API',
        productId: 'serp_advanced_google_autocomplete',
      },
      {
        label: 'Google Images Advanced API',
        productId: 'serp_advanced_google_images',
      },
      {
        label: 'Google Search Advanced API',
        productId: 'serp_advanced_google_search',
      },
      {
        label: 'Google Search API',
        productId: 'serp_essential_google_search',
      },
      {
        label: 'Google Shopping Advanced API',
        productId: 'serp_advanced_google_shopping',
      },
      {
        label: 'Google Trends Advanced API',
        productId: 'serp_advanced_google_trends',
      },
      {
        label: 'Naver Search Advanced API',
        productId: 'serp_advanced_naver_search',
      },
      {
        label: 'Yahoo! Search Advanced API',
        productId: 'serp_advanced_yahoo_search',
      },
      {
        label: 'Yandex Search Advanced API',
        productId: 'serp_advanced_yandex_search',
      },
    ],
    social: [
      {
        label: 'Instagram API',
        productId: 'social_extended_instagram',
      },
  /*    {
        label: 'LinkedIn API',
        productId: 'social_extended_linkedin',
      },*/
      {
        label: 'Reddit API',
        productId: 'social_extended_reddit',
      },
      {
        label: 'Snapchat API',
        productId: 'social_extended_snapchat',
      },
      {
        label: 'TikTok API',
        productId: 'social_extended_tiktok',
      },
  /*    {
        label: 'X (Twitter) API',
        productId: 'social_extended_twitter',
      },*/
      {
        label: 'YouTube API',
        productId: 'social_extended_youtube',
      },
    ],
    /*'Business Reviews': [
      {
        label: 'G2 Unblocker API',
        productId: 'business_reviews_g2_unblocker',
      },
    ],
    Tickets: [
      {
        label: 'Ticketmaster Unblocker API',
        productId: 'tickets_ticketmaster_unblocker',
      },
    ],*/
  },
}

export const randomCountry = {
  label: 'Any country',
  value: 'any',
}

export const randomRegion = {
  label: 'Any region',
  value: 'any',
}

export const randomCity = {
  label: 'Any city',
  value: 'any',
}

export const randomIsp = {
  label: 'Any ISP',
  value: 'any',
}
