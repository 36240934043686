import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBatteryLow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#3C4253"
      fillRule="evenodd"
      d="M9.764 13.243c.23.213.361.513.361.826v7.838a1.125 1.125 0 0 1-1.216 1.12L8.555 23a1.51 1.51 0 0 1-1.346-1.172 17.5 17.5 0 0 1 0-7.68 1.51 1.51 0 0 1 1.351-1.171m1.204.267c-.23-.213-.891-.292-1.204-.267z"
      clipRule="evenodd"
    />
    <path
      fill="#3C4253"
      fillRule="evenodd"
      d="M31.125 21v-6a.375.375 0 0 0-.375-.375h-2.1a1.125 1.125 0 0 1-1.125-1.125v-1.216c0-.731-.563-1.34-1.292-1.397l-1.903-.148a101 101 0 0 0-15.66 0l-2.021.157a1.71 1.71 0 0 0-1.556 1.425c-.63 3.76-.63 7.598 0 11.358.13.776.772 1.363 1.556 1.424l2.021.158c5.212.406 10.448.406 15.66 0l1.903-.148a1.4 1.4 0 0 0 1.292-1.398V22.5c0-.621.504-1.125 1.125-1.125h2.1a.375.375 0 0 0 .375-.375m2.25-6v6a2.625 2.625 0 0 1-2.625 2.625h-.975v.09a3.65 3.65 0 0 1-3.367 3.64l-1.903.15c-5.329.415-10.681.415-16.01 0l-2.021-.158a3.96 3.96 0 0 1-3.6-3.296 36.6 36.6 0 0 1 0-12.102 3.96 3.96 0 0 1 3.6-3.296l2.021-.157a103 103 0 0 1 16.01 0l1.902.148a3.65 3.65 0 0 1 3.368 3.64v.091h.975A2.625 2.625 0 0 1 33.375 15"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBatteryLow
