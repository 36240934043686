import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import styles from './PricingContent.module.scss'

/*import {
  Timer as TimerIcon,
  Present as PresentIcon,
  CheckedBox as CheckIcon,
} from 'components/icons'*/

// import TabsList from 'components/elements/tabs/TabsList'
import CustomToggle from 'components/elements/inputs/CustomToggle'
// import PromoBlock from 'components/elements/blocks/PromoBlock'
import PlansList from 'components/modules/pricing/PlansList/PlansList'
import PlansComparisonTable from 'components/modules/pricing/PlansComparisonTable'
import Loader from 'components/elements/progress/Loader'
import BillingFormModal from '../BillingFormModal'
import { logAmplitudeEvent } from 'utils/analytics'

import BestStarterReview from 'assets/images/reviews/best_starter_review.png'
import TopPerformerReview from 'assets/images/reviews/top_performer_review.png'
import HighPerformerReview from 'assets/images/reviews/high_performer_review.png'
import LeaderReview from 'assets/images/reviews/leader_review.png'
import BestRelationshipReview from 'assets/images/reviews/best_relationship_review.png'
import EasiestToDoReview from 'assets/images/reviews/easiest_to_do_review.png'
import BestValueReview from 'assets/images/reviews/best_value_review.png'
import GetAppReview from 'assets/images/reviews/get_app_review.png'
import CapterraReview2 from 'assets/images/reviews/capterra_review2.png'

import {
  selectPlansList,
  selectPlansFetching,
  /*selectInvoiceUrl,*/
  /*selectBillingModalVisibility,
  selectInvoiceModalStatus,*/
} from 'store/pricing/selectors'
import { IBillingInfoData, ISubscriptionPlan } from 'models/pricing'
import { PricingActionsTypes } from 'store/pricing/actions'
import FullActivationModal from '../FullActivationModal'
import { IUserSubscription } from '../../../../models/user'
import { selectSubscriptionInfo } from '../../../../store/user/selectors'

/*const trialPromoItems = [
  {
    label: '3 days',
    icon: <TimerIcon />,
  },
  {
    label: '$5 on your balance',
    icon: <PresentIcon />,
  },
  {
    label: 'All products',
    icon: <CheckIcon />,
  },
]*/

const priceTypesTabItems = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    label: 'Annually',
    value: 'annualy',
    note: 'save 30%',
  },
]

const PricingContent: React.FC = () => {
  const dispatch = useDispatch()

  const [activePriceTypeTabIndex /*, setActivePriceTypeTabIndex*/] = useState(0)
  const [isEnterpriseActive, toggleEnterprise] = useState(false)
  /*const [processingPlanId, setProcessingPlanId] = useState<string | null>(null)
  const [selectetdPlanId, setSelectedPlanId] = useState<string | null>(null)*/
  const [selectedPlan, setSelectedPlan] = useState<ISubscriptionPlan | null>(
    null
  )
  const [isFullActivationModalShown, toggleFullActivationModal] =
    useState(false)

  const plansList = useSelector(selectPlansList)
  const plansFetching = useSelector(selectPlansFetching)
  const subscriptionInfo: IUserSubscription = useSelector(
    selectSubscriptionInfo
  )
  // const invoiceUrl = useSelector(selectInvoiceUrl)
  // const isBillingModalShown = useSelector(selectBillingModalVisibility)
  // const isInvoiceModalShown = useSelector(selectInvoiceModalStatus)

  const reviews = [
    BestStarterReview,
    TopPerformerReview,
    HighPerformerReview,
    LeaderReview,
    BestRelationshipReview,
    EasiestToDoReview,
    BestValueReview,
    GetAppReview,
    CapterraReview2,
  ]

  const handleBillingModalClose = () => {
    /*setSelectedPlanId(null)
    setProcessingPlanId(null)*/

    setSelectedPlan(null)
    dispatch({ type: PricingActionsTypes.PRICING_HIDE_BILLING_FORM })
  }

  // const handleTabChange = (index: number) => setActivePriceTypeTabIndex(index)

  const handleEnterpriseToggle = () => toggleEnterprise(!isEnterpriseActive)

  const getActualPlans = () => {
    if (!plansList) return null

    if (isEnterpriseActive) {
      return plansList.filter(
        (product: ISubscriptionPlan) => product.type === 'enterprise'
      )
    } else {
      return plansList.filter(
        (product: ISubscriptionPlan) => product.type !== 'enterprise'
      )
    }
  }

  /*useEffect(() => {
    if (isBillingModalShown) {
      setProcessingPlanId(null)
    }
  }, [isBillingModalShown])

  useEffect(() => {
    if (isInvoiceModalShown) {
      setProcessingPlanId(null)
      setSelectedPlanId(null)
    }
  }, [isInvoiceModalShown])*/

  const actualPlans = getActualPlans()

  const activePriceType = priceTypesTabItems[activePriceTypeTabIndex].value

  if (plansFetching) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )
  }

  const handleSubscribe = (plan: ISubscriptionPlan) => {
    /*setProcessingPlanId(plan.id)
    setSelectedPlanId(plan.id)

    const subscriptionData = {
      plan_id: plan.id,
    }

    dispatch({
      type: PricingActionsTypes.PRICING_GET_INVOICE,
      subscriptionData,
    })*/

    setSelectedPlan(plan)

    if (plan.type === 'payg') {
      logAmplitudeEvent('click subscribe payg')
    } else if (!!plan.trial_price) {
      logAmplitudeEvent('click start subscription trial')
    }

    dispatch({
      type: PricingActionsTypes.PRICING_SHOW_BILLING_FORM,
    })
  }

  const handleActivateFullPlan = () => {
    logAmplitudeEvent('click Activate-full started')

    toggleFullActivationModal(true)
  }

  const handleCloseFullActivationModal = () => {
    toggleFullActivationModal(false)
  }

  const handleSubmitBillingForm = (data: IBillingInfoData) => {
    const subscriptionData = {
      ...data,
    }

    dispatch({
      type: PricingActionsTypes.PRICING_GET_INVOICE,
      subscriptionData,
    })
  }

  const reviewsList = reviews.map((item, index) => {
    return (
      <img
        className={styles.reviewItem}
        src={item}
        key={`review-item__${index}`}
        alt={'review'}
      />
    )
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        {/*<TabsList
          items={priceTypesTabItems}
          activeTabIndex={activePriceTypeTabIndex}
          size={'l'}
          color={'blue'}
          bordered
          onChange={handleTabChange}
        />*/}

        <div />

        <CustomToggle
          id={'enterprise-checkbox'}
          labelLeft={'Enterprise'}
          checked={isEnterpriseActive}
          onChange={handleEnterpriseToggle}
        />
      </div>

      <PlansList
        className={styles.plansList}
        plansList={actualPlans}
        activePriceType={activePriceType}
        onPlanSelect={handleSubscribe}
        onActivateFullPlan={handleActivateFullPlan}
        isEnterpriseActive={isEnterpriseActive}
        // processingPlanId={processingPlanId}
      />

      {/*{isTrialAvailable && (
        <PromoBlock
          title={'Try before subscribe'}
          image={`${process.env.PUBLIC_URL}/ball.png`}
          items={trialPromoItems}
          className={styles.promoBlock}
        />
      )}*/}

      <PlansComparisonTable
        plans={actualPlans}
        // processingPlanId={processingPlanId}
        onPlanSelect={handleSubscribe}
        onActivateFullPlan={handleActivateFullPlan}
        activePriceType={activePriceType}
      />

      <div className={styles.reviewList}>{reviewsList}</div>

      <BillingFormModal
        selectedPlan={selectedPlan}
        onModalClose={handleBillingModalClose}
        onSubmitBillingForm={handleSubmitBillingForm}
      />

      {!!subscriptionInfo?.full_plan_id && (
        <FullActivationModal
          isModalShown={isFullActivationModalShown}
          fullPlanPrice={subscriptionInfo.full_plan_price}
          fullPlanId={subscriptionInfo.full_plan_id}
          onModalClose={handleCloseFullActivationModal}
        />
      )}
    </div>
  )
}

export default PricingContent
